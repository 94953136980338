<nav [ngClass]="getCurrentPage()">
  <div class="nav-div inner-div container d-none d-md-flex flex-row">
    <a [routerLink]="['/home']" routerLinkActive="active-li" class="logo">
      <img
        height="100%"
        width="100%"
        src="assets/images/qualis-logo.png"
        alt=""
      />
    </a>

    <div class="link-div" id="">
      <ul class="">
        <li class="">
          <a
            [routerLink]="['/home']"
            routerLinkActive="active-li"
            class=""
            role="button"
          >
            Home
          </a>
        </li>
        <li
          class=""
          (mouseenter)="showModal = true; changeIcon = true; isHovered = true"
          (mouseleave)="
            showModal = false; changeIcon = false; isHovered = false
          "
        >
          <a
            [routerLink]="['/products']"
            routerLinkActive="active-li"
            class=""
            role="button"
          >
            Products
            <i *ngIf="changeIcon" class="mdi mdi-chevron-up"></i>
            <i *ngIf="!changeIcon" class="mdi mdi-chevron-down"></i>
          </a>
          <div class="modal-container container" *ngIf="showModal">
            <div class="modal-container1">
              <div class="modal-content">
                <div class="row justify-content-around">
                  <div
                    [routerLink]="['/products/starta']"
                    class="col-6 modal-div"
                  >
                    <div class="d-flex flex-column justify-content-center">
                      <h1>Starta</h1>
                      <h6>Business Finance</h6>
                      <p>
                        Apply for a Salary-backed business loan, get flexible
                        payment plans.
                      </p>
                    </div>
                  </div>

                  <div
                    [routerLink]="['/products/device']"
                    class="col-6 modal-div"
                  >
                    <div>
                      <h1>Device</h1>
                      <h6>Finance</h6>
                      <p>
                        Apply for a Salary-backed device finance loan. We
                        finance your device invoice while you pay slow.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-around mt-5">
                  <div
                    [routerLink]="['/products/skulu']"
                    class="col-6 modal-div"
                  >
                    <div class="">
                      <h1>Skulu</h1>
                      <h6>Student Finance</h6>
                      <p>
                        Apply for a Salary-backed tuition loan for you or your
                        childrens school fees.
                      </p>
                    </div>
                  </div>
                  <div class="col-6 modal-div1" disabled></div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="">
          <a
            [routerLink]="['/faq']"
            routerLinkActive="active-li"
            class=""
            role="button"
          >
            FAQs
          </a>
        </li>

        <li class="">
          <a [routerLink]="['/contact']" routerLinkActive="active-li" class="">
            Contact
          </a>
        </li>
        <li class="">
          <a
            [routerLink]="['/about-us']"
            routerLinkActive="active-li"
            class=""
            role="button"
          >
            About Us
          </a>
        </li>

        <li class="login">
          <a
            href="https://my-qualis.qualisdigital.co"
            target="_blank"
            routerLinkActive="active-li"
            class=""
          >
            Login
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Modal container -->

<!-- Background overlay -->
<div class="background-overlay" *ngIf="showModal"></div>

<!--mobile view-->
<nav class="d-md-none mobile">
  <div class="mobile-nav ps-3 pe-3">
    <div
      class="d-flex flex-row justify-content-between align-items-center d-md-none pt-3"
    >
      <div class="mobile-logo">
        <a [routerLink]="['/home']" routerLinkActive="active-li">
          <img
            height="100%"
            width="100%"
            src="assets/images/mobile-logo.png"
            alt=""
          />
        </a>
      </div>
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="mobile-login">
          <a href="https://my-qualis.qualisdigital.co" target="_blank">
            Login
          </a>
        </div>
        <button class="btn" (click)="open(content)">
          <img height="50px" width="50px" src="assets/images/open.png" alt="" />
        </button>
      </div>
    </div>
    <diV>
      <ng-template #content let-offcanvas>
        <div class="offcanvas-header">
          <div class="mobile-logo ms-2">
            <a
              [routerLink]="['/home']"
              routerLinkActive="active-li"
              role="button"
              aria-label="Close"
              (click)="offcanvas.dismiss('Cross click')"
            >
              <img
                height="100%"
                width="100%"
                src="assets/images/mobile-logo.png"
                alt=""
              />
            </a>
          </div>
          <div class="mobile-login">
            <a href="https://my-qualis.qualisdigital.co" target="_blank">
              Login
            </a>
          </div>
        </div>
        <div class="offcanvas-body d-flex flex-column align-items-center">
          <button
            type="button"
            class="btn ms-4"
            aria-label="Close"
            (click)="offcanvas.dismiss('Cross click')"
          >
            <img
              height="60px"
              width="60px"
              src="assets/images/close.png"
              alt=""
            />
          </button>
          <!--links-->
          <div class="link-div" id="">
            <ul class="">
              <li (click)="offcanvas.dismiss('Cross click')" class="">
                <a
                  [routerLink]="['/home']"
                  routerLinkActive="active-li"
                  class=""
                  role="button"
                >
                  Home
                </a>
              </li>
              <li (click)="changeIcon = !changeIcon">
                <a
                  class="product-a"
                  role="button"
                  (click)="isCollapsed = !isCollapsed"
                  [attr.aria-expanded]="!isCollapsed"
                  aria-controls="collapseExample"
                >
                  Products
                  <i *ngIf="changeIcon" class="mdi mdi-chevron-up"></i>
                  <i *ngIf="!changeIcon" class="mdi mdi-chevron-down"></i>
                </a>
              </li>
              <div #collapse="ngbCollapse" [(ngbCollapse)]="!isCollapsed">
                <div class="card mobile-collapse">
                  <div class="card-body mobile-card">
                    <div class="row">
                      <div
                        class="col-12 mobile-col"
                        (click)="offcanvas.dismiss('Cross click')"
                        [routerLink]="['/products/starta']"
                      >
                        <div class="collapse-div">
                          <h1>Starta</h1>
                          <h6>Business Finance</h6>
                          <p>
                            Apply for a Salary-backed business loan, get
                            flexible payment plans.
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-12 mobile-col"
                        (click)="offcanvas.dismiss('Cross click')"
                        [routerLink]="['/products/skulu']"
                      >
                        <div class="collapse-div">
                          <h1>Skulu</h1>
                          <h6>Student Finance</h6>
                          <p>
                            Apply for a Salary-backed tuition loan for you or
                            your childrens school fees.
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-12 mobile-col"
                        (click)="offcanvas.dismiss('Cross click')"
                        [routerLink]="['/products/device']"
                      >
                        <div class="collapse-div">
                          <h1>Device</h1>
                          <h6>Finance</h6>
                          <p>
                            Apply for a Salary-backed device finance loan. We
                            finance your device invoice while you pay slow.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <li (click)="offcanvas.dismiss('Cross click')" class="">
                <a
                  [routerLink]="['/faq']"
                  routerLinkActive="active-li"
                  class=""
                  role="button"
                >
                  FAQs
                </a>
              </li>

              <li (click)="offcanvas.dismiss('Cross click')" class="">
                <a
                  [routerLink]="['/contact']"
                  routerLinkActive="active-li"
                  class=""
                >
                  Contact
                </a>
              </li>
              <li (click)="offcanvas.dismiss('Cross click')" class="">
                <a
                  [routerLink]="['/about-us']"
                  routerLinkActive="active-li"
                  class=""
                  role="button"
                >
                  About Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="offcanvas-footer nav-footer">
          <p>2023, All Rights Reserved. Qualis Digital Finance</p>
        </div>
      </ng-template>
    </diV>
  </div>
</nav>
