import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

//declare var gtag: Function; // Declare gtag function

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  // Initialize Google Analytics with your Measurement ID
  //   initTracking(measurementId: string) {
  //     this.router.events.subscribe((event) => {
  //       if (event instanceof NavigationEnd) {
  //         gtag('config', measurementId, {
  //           page_path: event.urlAfterRedirects,
  //         });
  //       }
  //     });
  //   }

  // Track a page view
  //   trackPageView(pageTitle: string) {
  //     gtag('event', 'page_view', {
  //       page_title: pageTitle,
  //     });
  //   }

  // Track an event
  //   trackEvent(eventCategory: string, eventAction: string, eventLabel: string) {
  //     gtag('event', eventAction, {
  //       event_category: eventCategory,
  //       event_label: eventLabel,
  //     });
  //   }

  trackButtonClick(buttonName: string) {
    gtag('event', 'click', {
      event_category: 'Button Click',
      event_label: buttonName,
    });
  }

  logGtagEvent(eventLabel: string, eventValue: any) {
    gtag('event', eventLabel, eventValue);
  }
}
