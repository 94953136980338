import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  showModal = false;
  changeIcon = false;
  isHovered = false;

  public isCollapsed = false;

  constructor(
    private router: Router,
    private offcanvasService: NgbOffcanvas,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {}
  getCurrentPage() {
    const url = this.router.url;

    if (
      url === '/faq' ||
      url === '/apply/civil-servant-loan' ||
      url === '/apply/personal-loan' ||
      url === '/apply/mou-loan' ||
      url === '/apply/starta-loan' ||
      url === '/apply/skulu-loan' ||
      url === '/apply/device-loan'
    ) {
      return 'faq-navbar';
    } else if (url === '/contact' || url === '/about-us') {
      return 'contact-navbar';
    }

    return ''; // Default case when no class is applied
  }

  open(content: TemplateRef<any>) {
    this.offcanvasService.open(content, {
      position: 'end',
      scroll: true,
      panelClass: 'bg-black',
    });
    this.googleAnalyticsService.trackButtonClick('Mobile Open Menu Button');
  }
}
