const commonEnvironments = (baseUrl: string) => ({
  gaTrackingId: 'G-H7TCE340TS',
  sendFormUrl: `${baseUrl}/customer/onboarding`,
  allLoans: `${baseUrl}/customer/products`,
  contactUs: `${baseUrl}/customer/onboarding/contact-us`,
  validateShortId: 'https://dev-sso.qualisdigital.co/link',
  testingUrl: 'https://enhpzwr4lk1dc.x.pipedream.net/',
});

export default commonEnvironments;

export const googleAnalyticsTitle = {
  PAGE_VIEW: 'page_view',
  PRODUCT_NAME: 'qualis web',
  EVENT_CATEGORY: 'qualis',
};

export const pageTitle = {
  HOMEPAGE: 'Homepage',
  FAQ: 'FAQ Page',
  STARTA: 'Starta Loan',
  SKULU: 'skulu Loan',
  DEVICE: 'device Loan',
  CONTACT: 'Contact Page',
  ABOUT_US: 'About Us Page',
  APPLICATION_CIVILSERVANT: 'Civil Servant Loan Application',
  APPLICATION_PERSONAL: 'Personal Loan Application',
  APPLICATION_MOU: 'MOU loan Application',
  APPLICATION_STARTA: 'Starta Loan Application',
  APPLICATION_SKULU: 'Skulu Loan Application',
  APPLICATION_DEVICE: 'Device Loan application',
};
