<div class="app mt-5 d-none d-md-block">
  <div class="container">
    <div class="div-links">
      <div>
        <h1><a [routerLink]="['/home']" (click)="navigateTo()">Home</a></h1>
        <h2>
          <a [routerLink]="['/home']" (click)="navigateTo()"> Civil Servants</a>
        </h2>
        <h2>
          <a [routerLink]="['/home']" (click)="navigateTo()"> Personal loans</a>
        </h2>
        <h2>
          <a [routerLink]="['/home']" (click)="navigateTo()">MOU loans</a>
        </h2>
        <h2>
          <a [routerLink]="['/home']" (click)="navigateTo()">Business loans</a>
        </h2>
      </div>
      <div>
        <h1><a [routerLink]="['/about-us']">About Us </a></h1>
        <h2><a [routerLink]="['/about-us']">About Us </a></h2>
        <h2>Fraud guide</h2>
        <h2>Eligibility</h2>
      </div>
      <div>
        <h1><a [routerLink]="['/products']">Products </a></h1>
        <h2><a [routerLink]="['/products/starta']">Starta </a></h2>
        <h2><a [routerLink]="['/products/skulu']">Skulu</a></h2>
        <h2><a [routerLink]="['/products/device']">Device </a></h2>
      </div>
      <div>
        <h1>community</h1>
        <h2>
          <a href="https://www.facebook.com/QualisDF" target="_blank"
            >Social community</a
          >
        </h2>
        <h2>
          <a href="https://www.facebook.com/QualisDF" target="_blank">Blog</a>
        </h2>
      </div>
      <div>
        <h1>Contact Us <i class="mdi mdi-phone-outline"></i></h1>
        <h2>Inquiries</h2>
        <h3>
          <a href="mailto:info@qualisdigital.co">info&#64;qualisdigital.co</a>
        </h3>
        <h3>+260 768 630 577</h3>
        <h3>+260 972 002 311</h3>
        <h3>Monday to Friday (08h00 - 17h00)</h3>
        <h3>Saturdays (08h00 - 12h00)</h3>
      </div>
    </div>
  </div>
  <div class="container"><hr class="line" /></div>
  <div class="container">
    <div class="div-links">
      <div class="last-div">
        <div class="bg-img">
          <img
            height="82px"
            width="76px"
            src="assets/images/footer-logo.png"
            alt="logo"
          />
        </div>
        <h3>&copy; {{ currentYear }} Qualis Digital Finance</h3>
        <div class="footer-social">
          <a class="" href="https://wa.me/+260768630577" target="_blank">
            <img
              height="26px"
              width="26px"
              src="assets/images/whatsapp.png"
              alt="logo"
            />
          </a>
          <a
            class=""
            href="https://www.instagram.com/qualisdigitalfinance/"
            target="_blank"
          >
            <img
              height="26px"
              width="26px"
              src="assets/images/instagram.png"
              alt="logo"
          /></a>
          <a class="" href="https://www.facebook.com/QualisDF" target="_blank">
            <img
              height="26px"
              width="26px"
              src="assets/images/facebook.png"
              alt="logo"
          /></a>
          <a
            class=""
            href="https://www.linkedin.com/company/quatum-capital-zm/"
            target="_blank"
          >
            <img
              height="26px"
              width="28px"
              src="assets/images/linkedin.png"
              alt="logo"
            />
          </a>
          <a class="" href="https://twitter.com/QualisDigitalF" target="_blank">
            <img
              height="26px"
              width="26px"
              src="assets/images/twitter.png"
              alt="logo"
            />
          </a>
          <a
            class=""
            href="https://www.tiktok.com/@qualisdigitalfinance"
            target="_blank"
          >
            <img
              height="32px"
              width="32px"
              src="assets/images/tiktok.png"
              alt="logo"
            />
          </a>
        </div>
      </div>
      <div class="last-link">
        <h2><a [routerLink]="['/contact']"> Contact Us </a></h2>
      </div>
    </div>
  </div>
</div>

<!--Mobile footer-->
<footer class="mobile d-md-none ps-3 pe-3 mt-5">
  <section class="">
    <div class="text-center">
      <img
        height="70px"
        width="70px"
        src="assets/images/footer-mobile.png"
        alt="logo"
      />
    </div>
    <div class="mobile-links text-center">
      <div class="mt-4">
        <h1><a [routerLink]="['/home']" (click)="navigateTo()">Home</a></h1>
        <h2 class="">
          <a [routerLink]="['/home']" (click)="navigateTo()"> Civil Servants</a>
        </h2>
        <h2>
          <a [routerLink]="['/home']" (click)="navigateTo()"> Personal loans</a>
        </h2>
        <h2>
          <a [routerLink]="['/home']" (click)="navigateTo()">MOU loans</a>
        </h2>
        <h2>
          <a [routerLink]="['/home']" (click)="navigateTo()">Business loans</a>
        </h2>
      </div>
      <div class="mt-4">
        <h1>About Us</h1>
        <h2 class=""><a [routerLink]="['/about-us']">About Us </a></h2>
        <h2>Fraud guide</h2>
        <h2>Eligibility</h2>
      </div>
      <div class="mt-4">
        <h1><a [routerLink]="['/products']">Products </a></h1>
        <h2><a [routerLink]="['/products/starta']">Starta </a></h2>
        <h2><a [routerLink]="['/products/skulu']">Skulu</a></h2>
        <h2><a [routerLink]="['/products/device']">Device </a></h2>
      </div>
      <div class="mt-4">
        <h1>community</h1>
        <h2 class="">
          <a href="https://www.facebook.com/QualisDF" target="_blank"
            >Social community</a
          >
        </h2>
        <h2>
          <a href="https://www.facebook.com/QualisDF" target="_blank">Blog</a>
        </h2>
      </div>
    </div>
    <div class="mobile-icons mt-5">
      <div>
        <img
          height="40px"
          width="40px"
          src="assets/images/footer-icon.png"
          alt="logo"
        />
      </div>
      <a class="" href="https://wa.me/+260768630577" target="_blank">
        <img
          height="40px"
          width="40px"
          src="assets/images/mobile-whatsapp.png"
          alt="logo"
        />
      </a>
      <a
        class=""
        href="https://www.instagram.com/qualisdigitalfinance/"
        target="_blank"
      >
        <img
          height="40px"
          width="40px"
          src="assets/images/mobile-insta.png"
          alt="logo"
      /></a>
      <a class="" href="https://www.facebook.com/QualisDF" target="_blank">
        <img
          height="30px"
          width="30px"
          src="assets/images/mobile-fb.png"
          alt="logo"
      /></a>
      <a
        class=""
        href="https://www.linkedin.com/company/quatum-capital-zm/"
        target="_blank"
      >
        <img
          height="40px"
          width="40px"
          src="assets/images/mobile-linkedin.png"
          alt="logo"
        />
      </a>
      <a class="" href="https://twitter.com/QualisDigitalF" target="_blank">
        <img
          height="40px"
          width="40px"
          src="assets/images/mobile-x.png"
          alt="logo"
        />
      </a>
      <a
        class=""
        href="https://www.tiktok.com/@qualisdigitalfinance"
        target="_blank"
      >
        <img
          height="40px"
          width="40px"
          src="assets/images/mobile-tiktok.png"
          alt="logo"
        />
      </a>
    </div>
    <div class="mt-5 text-center text-white">
      <p>
        &copy; {{ currentYear }}, All Rights Reserved. Qualis Digital Finance
      </p>
    </div>
  </section>
</footer>
